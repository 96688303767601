/*Copyright (C) 2024 Xtremis, All rights reserved*/

.minimap-canvas-container {
    margin-top: 6px;
    width: 100%;
    height: 100%;
}

.minimap-canvas {
    width: 100%;
    height: 100%;
}
