/* Copyright (C) 2024 Xtremis, All rights reserved */

.gradient-slide-container{
    margin-bottom: 10px;
}

.gradient-slider-box {
    display: flex;
    gap: 5px;
    padding-top:10px;
    align-items: center;
}

.gradient-slider {
    position: relative;
    height: 10px;
    width: 100%;
    border: 1px solid var(--color-border);
    border-radius: 20px;
}

.gradient-slider-tick {
    position: absolute;
    top: -5px;
    transform: translate(-50%, 0);
    width: 10px;
    height: 18px;
    background-color: gray;
    cursor: pointer;
    border-radius: 10px;
    border: 1px solid white;
}

.tooltip {
    position: absolute;
    bottom: 25px; /* Adjusted for better visibility */
    left: 50%;
    transform: translateX(-50%);
    padding: 4px 8px;
    background: rgba(0, 0, 0, 0.8);
    color: white;
    font-size: 12px;
    border-radius: 5px;
    white-space: nowrap;
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
}

.tooltip.visible {
    opacity: 1;
}

.resetButton {
    color: var(--color-bg-button-active);
    width: 30px;
    cursor: pointer;
}
