/* Copyright (C) 2024 Xtremis, All rights reserved */

.status-selector{
    background-color: var(--color-bg-button-active);
    color:white;
    margin-left: 0;
    border-radius: 15px;
    padding: 2px;
    font-size: 12px;
}

.status-selector select option[value="NEW"]:focus{
    background-color: var(--color-bg-button-active);
    color:white;
    margin-left: 0;
    border-radius: 15px;
    padding: 2px;
    font-size: 12px;
}

.status-selector option[value="NEW"]{
    background-color: var(--color-node-new);
}

.status-selector option[value="WIP"] {
    background-color: var(--color-node-wip);
}

.status-selector option[value="DONE"] {
    background-color: var(--color-node-done);
}
