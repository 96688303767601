/* Copyright (C) 2024 Xtremis, All rights reserved */
body, input, textarea, button, select {
    font-family: 'Source Sans 3 Variable', sans-serif;
}

.container {
    background-color: #1c0f3b;
    display: flex;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
}
.login-div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
    color: white;
}

.login-button {
    font-size: 16px;
    padding: 10px 20px 10px 20px;
    border-radius: 10px;
}

.login-button:hover {
    background-color: #ccc;
}

button {
    padding: 8px 12px;
    border-radius: 8px;
    background-color: var(--color-bg-button-active);
    color: white;
    cursor: pointer;
}

button:hover {
    background-color: var(--color-text-button-hover);;
}

h1 {
    font-size: 2rem;
    margin-bottom: 20px;
}

.login-input {
    margin: 15px 0;
    padding: 10px;
    font-size: 16px;
    width: 300px;
    border-radius: 5px;
    border: 1px solid #ccc;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 1);
}

.control-panel {
    flex-shrink: 0;
    width: 340px;
    max-width: 340px;
}

.data-handling-panel {
    flex-grow: 1;
    overflow: hidden;
}
