.confidence-slider-container {
    margin-top: 10px;
}

.confidence-slider-container h3 {
    margin-bottom: 10px;
}

.confidence-slider-box {
    display: flex;
    gap: 5px;
    align-items: center;
}
.confidence-slider-track {
    position: relative;
    width: 100%;
    height: 10px;
    border: 1px solid #ccc;
    border-radius: 10px;
    background: linear-gradient(to right, black, white);
    cursor: pointer;
}

.confidence-slider-thumb {
    position: absolute;
    top: -5px;
    transform: translate(-50%, 0);
    width: 10px;
    height: 18px;
    background-color: gray;
    border-radius: 10px;
    cursor: pointer;
    border: 1px solid white;
}