/* Copyright (C) 2025 Xtremis, All rights reserved */

.diagonal-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    margin-bottom: 20px;
}

.radio-group {
    display: flex;
    text-align: center;
    gap: 12px;
    font-size: 0.9rem;
}

.radio-group label {
    display: flex;
    align-items: center;
    gap: 4px;
    cursor: pointer;
}

.radio-group input[type="radio"] {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    width: 16px;
    height: 16px;
    border: 2px solid var(--color-text-secondary);
    border-radius: 50%;
    position: relative;
    cursor: pointer;
}

.radio-group input[type="radio"]:checked {
    background-color: var(--color-text-button-ready);
}
.radio-group input[type="radio"]:checked::before {
    content: "";
    width: 6px;
    height: 6px;
    background: var(--color-text-secondary);
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}