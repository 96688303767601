/* Copyright (C) 2024 Xtremis, All rights reserved */

.selected-voxel-info-overlay {
    position: absolute;
    bottom: 5px;
    width: 78%;
    transform: translateY(100%);
    opacity: 0;
    transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
}

.selected-voxel-info-overlay.show {
    transform: translateY(0);
    opacity: 1;
}

.selected-voxel-info {
    background-color: #2c2c2c;
    color: #fff;
    border: 1px solid #444;
    border-radius: 4px;
    padding: 1rem;
    width: 100%;
    height: 100%;
    overflow-y: auto;
}