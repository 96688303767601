/* Copyright (C) 2024 Xtremis, All rights reserved */

.long-voxel-panel {
    display: grid;
    grid-template-rows: minmax(0, 1fr) var(--horizontal-axis-height);
    grid-template-columns: var(--main-canvas-axis-size) minmax(0, 1fr);
    margin-top: 10px;
    gap: 0;
    border: 0;
    min-height: 0;
    flex-grow: 1;
}

#info-icon {
    position: absolute;
    top: 0;
    right: 0;
    width: 42px;
    height: 42px;
    cursor: pointer;
    background: url('https://img.icons8.com/?size=100&id=646&format=png&color=FFFFFF') no-repeat center center;
    background-size: contain;
    z-index: 1001;
}

.data-handling-panel {
    color: white;
    display: flex;
    flex-direction: column;
    height: 100vh;
    width: 100%;
    position: relative;
    padding: 10px;
    box-sizing: border-box;
}

.data-handling-message {
    display: flex;
    align-items: center;
    justify-content: center;
}

.data-handling-panel.loading {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: var(--color-overlay);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999; /* Ensure it's on top of other elements */
}

.spinner {
    width: 60px;
    height: 60px;
    border: 6px solid var(--color-spinner-border);
    border-top-color: var(--color-spinner-border-top);
    border-radius: 50%;
    animation: spin 1s linear infinite;
}

/* Keyframes for the spinner animation */
@keyframes spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
