/* Copyright (C) 2024 Xtremis, All rights reserved */

.control-panel-container {
    display: flex;
    height: 100vh;
    width: 300px;
    overflow: hidden;
}

.hamburger-menu-wrapper {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--color-hamburger-bg);
    z-index: 1100;
    pointer-events: auto
}

.hamburger-menu-button {
    width: 30px;
    height: 30px;
    background-color: transparent;
    border: none;
    cursor: pointer;
    position: relative;
    outline: none;
    z-index: 1100;
    pointer-events: auto;
}

.hamburger-menu-button .bar {
    width: 25px;
    height: 3px;
    background-color: var(--color-panel-background-light);
    border-radius: 2px;
    margin: 4px auto;
    transition: all 0.3s ease;
}

.hamburger-menu-button.active .bar:nth-child(1) {
    transform: rotate(45deg) translate(5px, 5px);
}

.hamburger-menu-button.active .bar:nth-child(2) {
    opacity: 0;
}

.hamburger-menu-button.active .bar:nth-child(3) {
    transform: rotate(-45deg) translate(5px, -5px);
}

.side-icons {
    display: flex;
    flex-direction: column;
    gap: 12px;
    align-items: center;
    margin-bottom: 24px;
    z-index: 1099;
}

/* Icon buttons with white outlines */
.icon-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    background-color: var(--color-bg-button-hover);
    border: 2px solid #ffffff00; /* White outline */
    border-radius: 0;
    cursor: pointer;
    outline: none;
    transition: background-color 0.3s ease, transform 0.2s ease;

}

.tab-button {
    background-color: #006400;
    width: 35px;      
    height: 35px;     
    padding: 0;       
    text-align: center;
}

.tab-button.active {
    background-color: var(--color-bg-button-active);
}

.icon-btn.active {
    background-color: var(--color-bg-button-active);
    color: var(--color-text-button-active);
    transform: scale(1.05);
}

.icon-btn:hover {
    background-color: var(--color-bg-button-hover);
    color: var(--color-text-button-hover);
}

/* Container that holds side panel + main content */
.control-panel-inside-container {
    display: flex;
    flex-direction: row;
    width: 360px;
    max-width: 360px;
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    background-color: var(--color-bg-primary);
    z-index: 1002;
    box-shadow: 1px 0 3px var(--color-shadow);
    overflow: hidden;
}

.control-panel-inside-container.open .side-panel {
    transform: translateX(0);
    padding: 20px;
    overflow-y: auto;
}

/* Side Panel */
.side-panel {
    position: fixed;
    top: 0;
    left: 30px;
    width: 330px;
    max-width: 330px;
    padding: 40px;
    height: 100%;
    background-color: var(--color-side-panel-bg);
    box-shadow: 1px 0 3px var(--color-shadow);
    box-sizing: border-box;
    transform: translateX(-100%);
    transition: transform 0.3s ease-in-out;
    z-index: 1001;
    color: var(--color-text);
    overflow-y: hidden;
}

.control-panel-inside {
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 1rem;
    margin-left: 40px;
    color: var(--color-text-secondary);
    border-right: 4px solid transparent;
    background: linear-gradient(45deg, var(--color-hamburger-bg), var(--color-minimap-bg)) border-box,
    var(--color-hamburger-bg);
    background-clip: padding-box, border-box;
    transition: margin-left 0.3s ease-in-out;
    max-width: 360px;
}

/* Overlay */
.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: var(--color-bg-overlay);
    z-index: 999;
    cursor: pointer;
    transition: opacity 0.3s ease-in-out;
}

.cp-main-text {
    font-size: 16px;
    font-weight: bold;
    padding-top: 5px;
}