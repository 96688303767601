/* Copyright (C) 2024 Xtremis, All rights reserved */

.tree-view {
    padding: 10px;
    display: flex;
    flex-direction: column;
}

.tree-view-filter {
    display: flex;
    align-items: center;
    margin: 10px 0 10px 0;
}

.tree-view input {
    background: linear-gradient(45deg, var(--color-hamburger-bg), var(--color-minimap-bg));
    border-radius: 8px;
    font-size: 0.9rem;
    color: var(--color-text);
    border: 1px solid var(--color-border);
    padding: 2px;
}

.tree-node {
    display: flex;
    align-items: center;
    cursor: pointer;
    user-select: none;
    padding: 4px 0;
    color: black;
}

.tree-node.NEW {
    background-color: var(--color-node-new);
}

.tree-node.WIP {
    background-color: var(--color-node-wip);
}

.tree-node.DONE {
    background-color: var(--color-node-done);
}

.tree-node.selected {
    background-color: #cce5ff;
    border-left: 10px solid #007bff;
}

.tree-node.folder:hover,
.tree-node.file:hover {
    background-color: #e0e0e0;
}

.tree-icon {
    margin-right: 5px;
    width: 20px;
    text-align: center;
}

.tree-name {
    flex-grow: 1;
}

.status-selector {
    background-color: var(--color-bg-button-active);
    color: white;
    margin-left: 0;
    border-radius: 15px;
    padding: 2px;
    font-size: 12px;
}

.status-selector select option[value="NEW"]:focus {
    background-color: var(--color-bg-button-active);
    color: white;
    margin-left: 0;
    border-radius: 15px;
    padding: 2px;
    font-size: 12px;
}

.status-selector option[value="NEW"] {
    background-color: var(--color-node-new);
}

.status-selector option[value="WIP"] {
    background-color: var(--color-node-wip);
}

.status-selector option[value="DONE"] {
    background-color: var(--color-node-done);
}
