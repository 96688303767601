.tool_mode_header {
    margin-top: 20px;
}

.measurements-container {
    margin-top: 20px;
}

.measurements-container p {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 10px;
}

.measurements-container ul {
    list-style-type: none;
    padding-left: 10px;
}

.measurements-container ul li {
    cursor: pointer;
}

.measurements-container ul li:hover {
    background-color: var(--color-bg-button-active);
    color: var(--color-text);
}

.measurements-container ul li:hover b {
    color: var(--color-text);
}

.measurements-container ul li b {
    color: var(--color-text-button-hover);
}

.measurements-container ul li span b {
    padding-left: 10px;
}

.info-menu-left {
    padding-left: 20px;
}

.info-menu-top {
    padding-top: 10px;
}

.info-menu-receivers {
    margin-left: 20px;
    margin-bottom: 5px;
}
