/* Copyright (C) 2024 Xtremis, All rights reserved */
html, body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}

:root {
  /* Primary Palette */
  --color-text: #ffffff;
  --color-text-dark: #000000;
  --color-text-secondary: #cccccc;
  --color-panel-background: #000000;
  --color-panel-background-light: #ffffff;
  --color-overlay: rgba(0,0,0,0.4);
  --color-axis: #cccccc;
  --color-grid: #444444;
  --color-label: #ffffff;
  
  /*File explorer colors*/
  --color-node-new: #ffe8a1; 
  --color-node-wip: #fcb08c;
  --color-node-done: #a8e5b8;

  /* Spinner */
  --color-spinner-border: #ccc;
  --color-spinner-border-top: #1c0f3b;

  /* Info Icon */
  --color-info-icon: #ffffff;

  /* PSDDiagram */
  --color-bins: #4caf50;

  /* Bounding Box Handles & Lines */
  --color-line-axis: #1c0f3b;
  --color-box-selected: rgb(10, 253, 62); 
  --color-box-default: rgb(255, 255, 255);  
  --color-box-background: rgba(255, 0, 0, 0.2);

  /* Long Voxel Panel Bins */
  --color-bin-selected: rgb(132, 0, 255);
  --color-bin-default: lightgray;
  --color-bin-border: black;

  /* Tooltip */
  --color-tooltip-bg: rgba(0, 0, 0, 0.8);
  --color-tooltip-text: #fff;

  /* Control Panel */
  --color-hamburger-bg: #1c0f3b;
  --color-hamburger-bg-hover: #3a3a3a;
  --color-hamburger-bar: #cccccc;
  --color-bg-primary: #1c0f3b;
  --color-minimap-bg: #270644;
  --color-side-panel-bg: #1c0f3b;
  --color-border: #ddd;
  --color-shadow: rgba(0, 0, 0, 0.2);

  /* Overlay */
  --color-bg-overlay: rgba(0, 0, 0, 0.3);

  /* Buttons */
  --color-bg-button-default: #a3a2a2;
  --color-bg-button-hover: #a5d6a7;
  --color-bg-button-active: #28a745;
  --color-bg-button-disabled: #d6d6d6;
  --color-bg-button-ready: #c8e6c9;
  --color-text-button-default: #fff;
  --color-text-button-ready: #2e7d32;
  --color-text-button-hover: #1b5e20;
  --color-text-button-active: #fff;
  --color-text-button-disabled: #a1a1a1;

  --horizontal-axis-height: 30px;
  --main-canvas-axis-size: 60px;
  --min-bin-width: 4px;
  --psd-diagram-height: 250px;
  --long-voxel-panel-height: 20px;
}