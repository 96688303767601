/* Copyright (C) 2024 Xtremis, All rights reserved */

.help-menu {
    position: fixed;
    display: flex;
    flex-direction: column;
    right: 50px;
    z-index: 1002;
    border: 1px solid gray;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    background-color: var(--color-panel-background-light);
    padding: 16px;
    max-width: 70%;
    max-height: 80%;
    color: #000;
}

.info {
    overflow: auto;
    display: flex;
}

.help-menu .button-container {
    display: flex;
    align-items: end;
}

.help-menu h3 {
    margin-top: 0;
    color: var( --color-text-dark);
    margin-bottom: 5px;
}

.help-menu ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
    min-width: 200px;
}

.help-menu li {
    margin-bottom: 8px;
}

.help-menu button {
    margin-top: 10px;
    margin-right: 10px;
    border: none;
}

.help-menu button:hover {
    background-color: var(--color-text-button-hover);;
}

.logout-button{
    margin-left: 10px;
}
