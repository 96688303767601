/* Copyright (C) 2024 Xtremis, All rights reserved*/

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.toggle-container {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.toggle-container h3 {
    padding-top: 0;
    margin-right: 10px;
}

.toggle-switch {
    width: 2.3rem;
    height: 16px;
    border-radius: 1rem;
    position: relative;
}

.toggle-switch.on {
    background: var(--color-bg-button-active);
}

.toggle-switch.off {
    background: var(--color-bg-button-default);
}

.toggle-circle {
    width: 14px;
    height: 14px;
    border-radius: 50%;
    background: var(--color-text, #fff);
    position: absolute;
    top: 1px;
    transition: left 0.3s ease, transform 0.3s ease;
}

.toggle-circle.on {
    left: 1.3rem;
}

.toggle-circle.off {
    left: 0.2rem;
}
