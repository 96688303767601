/* Copyright (C) 2024 Xtremis, All rights reserved */

.main-canvas-container {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    min-height: 0;
}

/* Canvas styles */
.main-canvas {
    flex-grow: 1;
    border: 0;
    min-height: 0;
    display: block;
    cursor: pointer;
}

.hovered-bbox-tooltip {
    position: absolute;
    background-color: rgba(0, 0, 0, 0.8);
    color: #fff;
    padding: 5px;
    border-radius: 5px;
    pointer-events: none;
    font-size: 12px;
    white-space: nowrap;
    z-index: 1000;
}

/* Overlay container covering the viewport */
.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1000; 
    display: flex;
    align-items: center;
    justify-content: center;
  }

/* Inner popup box styling */
.small-box-popup {
    background: #333;
    color: #fff;
    border: 1px solid #444;
    padding: 1rem;
    min-width: 240px;
    text-align: center;
    border-radius: 4px;
  }