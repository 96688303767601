/* Copyright (C) 2024 Xtremis, All rights reserved */

.scrollable-table-container {
    min-height: 68px;
    max-height: 136px;
    overflow-y: auto;
    overflow-x: auto;
    box-sizing: border-box;
}

.button-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 5px;
}

.button-table th,
.button-table td {
    border: 1px solid var(--color-border);
    text-align: center;
    padding: 4px;
}

.button-table button {
    padding: 4px 8px;
    cursor: pointer;
    background-color: var(--color-bg-button-default);
    color: var(--color-text-button-default);
    border: none;
    border-radius: 4px;
    transition: background-color 0.3s ease, transform 0.2s ease;
}

.button-table button:disabled,
.button-table button:disabled:hover {
    background-color: var(--color-bg-button-disabled);
    color: var(--color-text-button-disabled);
    cursor: not-allowed;
    opacity: 0.8;
    pointer-events: none;
}

.button-table button:not(:disabled):not(.active) {
    background-color: var(--color-bg-button-ready);
    color: var(--color-text-button-ready);
}

.button-table button:not(:disabled):not(.active):hover {
    background-color: var(--color-bg-button-hover);
    color: var(--color-text-button-hover);
}

.button-table button.active {
    background-color: var(--color-bg-button-active);
    color: var(--color-text-button-active);
    transform: scale(1.05);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    pointer-events: none;
}

.toggles {
    margin-top: 20px;
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
    max-width: 300px;
    align-items: center;
}

.toggle-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 0.9rem;
    text-align: center;
}

.minimap-container {
    flex-grow: 1;
    justify-content: center;
    align-items: center;
}
