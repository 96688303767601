.dropdown-container {
    display: flex;
    padding-top: 10px;
}

.dropdown-container select {
    width: 48%;
    font-size: 0.9rem;
    color: var(--color-text);
    background: linear-gradient(45deg, var(--color-hamburger-bg), var(--color-minimap-bg));
    border: 1px solid var(--color-border);
    border-radius: 8px;
    outline: none;
    transition: all 0.3s ease;
    cursor: pointer;
}

.dropdown-container option {
    color: var(--color-text);
    background: var(--color-hamburger-bg);
}
